import { template as template_c7521f2e24364e969fd2b7fe019a1465 } from "@ember/template-compiler";
const FKLabel = template_c7521f2e24364e969fd2b7fe019a1465(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
